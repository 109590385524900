import React from "react";
import NextHead from "next/head";
import strings from "~/resources/strings";

export const SiteHead: React.FC = () => {
	return (
		<NextHead>
			<meta name="facebook-domain-verification" content="0d8oe5s5up43tv0dtqoapunwi90cjx" />
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" />
			<meta name="google-site-verification" content="1hWx7fCK-gnF6RWrcYV8lYhaZn4WaiG1Mhp7HeT-dXM" />
			<title>{strings.seo.title}</title>
			<link rel="shortcut icon" href="/logo.ico" />
			<meta
				key="keywords"
				name="keywords"
				content="Franquia aluguel de roupas, Franquia de aluguel de roupas, franquia de roupas, como montar uma loja de aluguel de vestidos de festa, Franquia vestidos de festa"
			/>
			<link href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700;800&display=swap" rel="stylesheet" />
			{/* Google */}
			<script async src="https://www.googletagmanager.com/gtag/js?id=G-VB9D8JXJVH" />
			<script
				dangerouslySetInnerHTML={{
					__html: `function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id=%27+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-W7Q5KTT');`,
				}}
			/>
		</NextHead>
	);
};
