import React from "react";
import { Flex, Box, BoxProps } from "@chakra-ui/react";

interface IProps {
	contentContainerProps?: BoxProps;
}

export const MainLayout: React.FC<IProps> = (props) => {
	const { children, contentContainerProps } = props;
	return (
		<Flex
			minH="100vh"
			flexDirection="column"
			bgColor="secondary.50"
			width="100%"
		>
			<Box minH="20vh" {...contentContainerProps} >
				{children}
			</Box>
		</Flex>
	);
};
