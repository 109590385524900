import React from "react";
import { Box } from "@chakra-ui/react";

export const PageContainer: React.FC = (props) => {
	const { children } = props;

	return (
		<Box
			maxW="1440px"
			my={{ base: "0.5rem", md: "5rem" }}
			mx="auto"
		>
			{children}
		</Box>
	);
};

