import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

export const Input: ComponentStyleConfig = {
	defaultProps: {
		variant: "outline",
	},
	variants: {
		outline: (props) => ({
			...theme.components.Input.variants.outline(props),
			field: {
				color: "secondary.900",
				bg: "transparent",
				_placeholder: {
					color: "secondary.900",
				},
				h: 69,
				borderRadius: 12,
				borderColor: "primary.400",
				rounded:"xl",
				_focus:{
					border: "1px solid black",
					outline: 0,
				},
				_hover: "none",
			},
		}),
	},
};
