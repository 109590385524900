import { extendTheme } from "@chakra-ui/react";
import { spacing } from "./spacing";
import { fontSizes } from "./fontSizes";
import { colors } from "./colors";
import { components } from "./components";

export const theme = extendTheme(
	{
		styles: {
			global: {
				"html, body": {
					fontFamily: "Manrope, sans-serif",
				},
			},
		},
		colors,
		fontSizes,
		spacing,
		components,
	},
);
