/* eslint-disable max-len */
import LocalizedStrings from "localized-strings";
import format from "./format";

const strings = new LocalizedStrings({
	ptBr: {
		error: {
			default: "Um erro ocorreu, tente mais tarde.",
			invalidArgument: "Argumento inválido",
			stillLoading: "Ainda Carregando, aguarde.",
			emptyFields: "Os campos nome, email e telefone não podem estar vazios.",
		},
		common: {
			mask: {
				date: "__/__/____",
				time: "__:__",
			},
			buttons: {
				iWantMyFranchise: "Quero minha franquia",
				iWantToBeFranchised: "Quero ser franqueada",
				iWantKnowMore: "Quero saber mais",
				downloadBusinessPlan1: "Baixar plano de negócio",
				downloadBusinessPlan2: "Baixe o plano de negócios",
			},
		},
		seo: {
			title: "LOC - Franquia de Aluguel de Roupas",
			url: "https://franquia.meuloc.com/",
			description: "Franquia inovadora, desruptiva e sustentável. Investimento a partir de R$15.000,00. Modelo de negócio prático e enxuto.",
		},
		formatters: format,
		sections: {
			franchiseTable: {
				table: {
					title: "Franquia convencional",
					rows: [
						{
							title: "Investimento com obras e intalação inicial:",
							value: "R$ 100.000",
						},
						{
							title: "Aluguel do espaço de 60m²:",
							value: "R$ 5.000",
						},
						{
							title: "Contratação do time de 3 pessoas:",
							value: "R$ 5.000",
						},
						{
							title: "Investimento em estoque de 100 peças:",
							value: "R$ 100.000",
						},
						{
							title: "Pagamento taxa mensal de marketing:",
							value: "R$ 1.000",
						},
					],
					totalFee: {
						title: "Taxa de franquia total:",
						value: "R$ 211.000",
					},
					observation: "+ taxa de franquia sendo R$11.000 custo fixo mensal",
				},
				locFranchise: "Franquia loc",
				youPay: "Você paga ",
				just: "Apenas ",
				theFranchiseFee: "A taxa de franquia",
			},
			allMoments: {
				text: {
					firstPart: "Leve para a sua cidade o app que é sua ",
					strong: "melhor amiga ",
					lastPart: "para todos os momentos.",
				},
			},
			businessPlan: {
				alt: {
					iphoneFrame: "IPhone LOC App",
				},
				title: "Existe aluguel de roupa e ",
				exist: "existe",
				topics: [
					{
						title: "➝ Consumo inteligente",
						description: "Alugue peças que você vai usar em ocasiões pontuais, daquela viagem para o frio até um jantar especial.",
					},
					{
						title: "➝ Armário digital",
						description: "Crie sua wishlist digital das suas peças preferidas, alugue quando quiser usar, devolva sem lavar.",
					},
					{
						title: "➝ Use todas as tendências",
						description: "Siga os toplocs que mais gosta para ver as novidades, se inspirar e usar.",
					},
					{
						title: "➝ Renda extra sem investimento",
						description: "Ganhe em cima de peças que você ja tem no seu armário. A peça que você coloca em seu LOC pode se pagar com a quantidade de aluguéis.",
					},
				],
				moreThan: "Mais do que um ",
				clothingRental: "aplicativo de aluguel ",
				locHasBecome: "de roupas, o loc se tornou uma ",
				fashionSocialNetwork: "Rede Social de moda.",
			},
			initial: {
				alt: {
					mobileFrame: "IPhone LOC",
				},
				title: "compartilhe seu mundo",
				subtitleInvestiment: "Investimento a partir de R$17.400.",
				subtitleModelBusiness: "Modelo de negócio prático e enxuto.",
				whyLoc: "Por que o",
				interrogation: "?",
				be: "Seja ",
				franchised: "franqueada ",
				of: "do ",
				firstAppOf: "primeiro aplicativo de moda compartilhada do país.",
				moreOf: "Mais de ",
				numberOfPieces: "80.000 peças ",
				forYou: "para você ",
				useAndReturn: "usar, curtir e devolver.",
			},
			depoiment: {
				title: "Depoimentos",
			},
			highlights: {
				alt: {
					iphoneFrame: "IPhone LOC",
				},
				title: "Outros pontos de destaque:",
				subtitle: "Quero fazer parte da ",
				expansion: "expansão!",
				topics: [
					{
						icon: "img/digital.png",
						title: "Revolução Digital",
						description: "Faça parte do movimento digital com um aplicativo inovador.",
					},
					{
						icon: "img/lean-structure.png",
						title: "Estrutura Enxuta",
						description: "Sem custos fixos e sem necessidade de contratações.",
					},
					{
						icon: "img/scale.png",
						title: "Ganhos com Escala",
						description: "Você ganha a cada transação feita em sua cidade.",
					},
					{
						icon: "img/support.png",
						title: "Suporte",
						description: "Suporte para todas as áreas de contato.",
					},
				],
			},
			contactForm: {
				subscribe: "Se inscreva ",
				firstText: "para receber o ",
				businessPlan: "plano de negócios ",
				finalText: "da franquia:",
				placeholders: {
					name: "Nome",
					email: "Email",
					phone: "Telefone",
					instagram: "Instagram",
					city: "Cidade",
					state: "Estado",
				},
				submit: "Baixe o plano de negócios",
			},
		},
		pages: {
			home: {
				contact: {
					success: "Formulario enviado com sucesso!",
				},
			},
		},
	},
	components: {
		cardDepoiment: {
			alt: {
				client: "Foto de um dos clientes da Loc",
				quotesIcon: "Ícone rosa simbolizando duas aspas",
			},
		},
	},
},
);

export default strings;
