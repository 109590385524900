const green = {
	50: "#F7FBEA",
	100: "#EAF4C3",
	200: "#DCEC9C",
	300: "#CFE576",
	400: "#C1DE4F",
	500: "#C9E265",
	600: "#90AC20",
	700: "#6C8118",
	800: "#485610",
	900: "#242B08",
};

const pink = {
	50: "#FCE8EB",
	100: "#F7BFC8",
	200: "#000000",
	300: "#000000",
	400: "#000000",
	500: "#000000",
	600: "#000000",
	700: "#000000",
	800: "#000000",
	900: "#2D060B",
};

const gray = {
	50: "#F5F5F5",
	100: "#E5E5E5",
	200: "#C4C4C4",
	300: "#ADADAD",
	400: "#8F8A8A",
	500: "#737373",
	600: "#5E5E5E",
	700: "#4A4A4A",
	800: "#333333",
	900: "#1A1A1A",
};

export const colors = {
	pink,
	green,
	primary: pink,
	secondary: gray,
};
