import React from "react";

export const Body: React.FC = () => (
	<body>
		<noscript>
			<iframe
				src="https://www.googletagmanager.com/ns.html?id=GTM-W7Q5KTT"
				height="0"
				width="0"
				style={{display: "none", visibility: "hidden"}}
			/>
		</noscript>
	</body>
);
