import { ComponentStyleConfig } from "@chakra-ui/react";
import { theme } from "@chakra-ui/theme";

export const Button: ComponentStyleConfig = {
	defaultProps: {
		variant: "solid",
	},
	variants: {
		solid: (props) => ({
			...theme.components.Button.variants.solid(props),
			borderWidth: "2px",
			borderRadius: "100px",
			borderColor: "primary.400",
			textColor: "primary.50",
			bg: "primary.400",
			py: 6,
			_hover: {
				bg: "transparent",
				textColor: "primary.400",
				transition: "1s ease 0s",
			},
		}),
		outline: (props) => ({
			...theme.components.Button.variants.outline(props),
			borderWidth: "2px",
			borderRadius: "100px",
			borderColor: "primary.400",
			textColor: "primary.400",
			bg: "transparent",
			py: 6,
			_hover: {
				bg: "primary.400",
				textColor: "primary.50",
				transition: "1s ease 0s",
			},
		}),
		secondary: (props) => ({
			...theme.components.Button.variants.outline(props),
			borderWidth: "2px",
			borderRadius: "100px",
			borderColor: "secondary.50",
			textColor: "secondary.900",
			bg: "secondary.50",
			py: 6,
			_hover: {
				bg: "secondary.100",
				borderColor: "secondary.100",
			},
		}),
	},
};
